body {
  font-family: 'Robote', 'Helvetica', 'Arial', 'sans-sarif';
}

.MuiContainer-maxWidthLg {
  max-width: 3200px !important;
}

a {
  text-decoration: none;
  color: #0078d4;
}