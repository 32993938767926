.docs-footer {
    padding: 48px 0px;
    margin-bottom: -64px;
}

    .docs-footer p {
        padding-top: 5px;
    }

    .docs-footer ul li {
        padding: 3px 0;
    }